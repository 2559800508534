import { gql } from 'graphql-tag';
import SummaryFragment from '@fragments/summaries/SummaryFragment';
import FileFragment from '@fragments/shared/FileFragment';

export default gql`
    fragment SubnavFragment on subNav {
        navigationLinks {
            label
            reference {
                title
                path
            }
            hasSublinks
            sublinks {
                label
                reference {
                    title
                    path
                }
            }
        }
        socialNetworks {
            network
            handle
            id
        }
        button {
            appearance
            label
            type
            url
            reference {
                ...SummaryFragment
            }
            file {
                ...FileFragment
            }
            newTab
        }
    }

    ${SummaryFragment}
    ${FileFragment}
`;
